import * as yup from "yup";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const phoneRegExp = /^\+?\d{1,15}$/;

export const contactSchema = yup.object().shape({
  full_name: yup.string().min(3, 'Full Name must be at least 3 characters').required("Please enter your name"),
  email: yup.string().email('Email must be a valid email').matches(emailRegx, "Please enter the email"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number must start with + followed by up to 15 digits"),
    // .required("Please enter your phone number"),
  subject: yup.string().min(5, 'Subject must be at least 5 characters'),
  message: yup.string().min(5, 'Message must be at least 5 characters').required("Please enter your message"),
});

